export const firebaseConfig = {
    apiKey: "AIzaSyDLmPi6ZB8LVzsO0GMSsAqQvEjdKEVMxIU",
    authDomain: "qa-omnicure.firebaseapp.com",
    databaseURL: "https://qa-omnicure.firebaseio.com",
    projectId: "qa-omnicure",
    storageBucket: "qa-omnicure.appspot.com",
    messagingSenderId: "699680151510",
    appId: "1:699680151510:web:c072a699783faea863b677",
    measurementId: "G-H4WCYHYB47",
    speechToTextURL: "https://streaming-speech-to-text-dot-qa-omnicure.uc.r.appspot.com"
  };
  